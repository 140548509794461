import S from './style';
import Banner1Image from '../../images/banner1.png';
import Banner2Image from '../../images/banner2.png';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import { UOSLINK_WEBSITE, KAKAO_CHANNEL } from '../../constants/LinkNames';
import { bannerAPI } from '../../api/config';

const bannerInfo = [
  {
    id: 1,
    url: UOSLINK_WEBSITE,
    image: Banner1Image,
  },
  {
    id: 2,
    url: KAKAO_CHANNEL,
    image: Banner2Image,
  }
]

const Slider = () => {
  const clickBanner = (banner) => {
    window.open(banner.url, '_blank');
    bannerAPI.recordClick(banner.id);
  };

  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      style={{
        width: '100%',
        height: '156px',
        cursor: 'pointer',
        borderRadius: '8px',
      }}
      autoplay={{delay: 5000}}
      loop={true}>
      {bannerInfo.map(banner => (
        <SwiperSlide key={banner.id}>
          <S.BannerImage src={banner.image
          } onClick={() => clickBanner(banner)} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
