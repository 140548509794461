/* global chrome */
import React, { useState, useEffect } from "react";
import { ServiceNoticeWrap, TailIconWrap, ServiceNoticeText } from "./style";
import tailIcon from "../../images/tail-icon.svg";
import serviceNoticeIcon from "../../images/service-notice-icon.svg";
import { versionAPI } from "../../api/config";

const ServiceNotice = () => {
  const [isShow, setIsShow] = useState(false);
  const [serviceNotice, setServiceNotice] = useState("");

  const getVersion = () => {
    try {
      return chrome.runtime.getManifest().version;
    } catch (error) {
      return '1.0.2';
    }
  };  

  useEffect(() => {
    setTimeout(() => {
      versionAPI.checkVersion(getVersion())
        .then(response => {
          setIsShow(response.status !== 200);
          if (response.status !== 200) {
            setTimeout(() => setIsShow(false), 10000);
          }
        })
        .catch(error => {
          if (error.response?.status === 300) {
            setServiceNotice(error.response.data);
            setIsShow(true);
          } else if (error.response?.status) {
            setIsShow(true);
          }
          setTimeout(() => setIsShow(false), 10000);
        });
    }, 3000);
  }, []);

  function clickServiceNotice() {
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  }

  return (
    <ServiceNoticeWrap isShow={isShow} onClick={clickServiceNotice}>
      <TailIconWrap src={tailIcon} />
      <ServiceNoticeText type="blue" isShow={isShow}>
        <img src={serviceNoticeIcon} />
        공지
      </ServiceNoticeText>
      <ServiceNoticeText isShow={isShow}>
        {serviceNotice ? (
          serviceNotice
        ) : (
          <div>
            <b>새로운 버전</b>이 업데이트 되었어요! 크롬을 재시작 하면 업데이트
            됩니다.
          </div>
        )}
      </ServiceNoticeText>
    </ServiceNoticeWrap>
  );
};

export default ServiceNotice;
