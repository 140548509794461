import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_UOSLINK_API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const endpoints = {
  noticeRanking: '/notice/ranking',
};

export const noticeAPI = {
  getRanking: () => apiClient.get('/notice/ranking'),
  getMajorNotice: (majorId) => apiClient.get(`/notice/major/${majorId}`),
};

export const foodAPI = {
  getFoodMenu: (place, date) => apiClient.get(`/food/${place}/${date}`),
};

export const libraryAPI = {
  getLibraryInfo: (place) => apiClient.get(`/scraping/library/${place}`),
};

export const versionAPI = {
  checkVersion: (version) => apiClient.get(`/check/version/${version}`),
};

export const bannerAPI = {
  recordClick: (bannerId) => apiClient.post('/click/banner', {
    banner_id: String(bannerId)
  }),
};

export default apiClient;